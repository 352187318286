<template lang="pug">
  labels(:value="labels" hide-exclude @changed="submit")
    template(v-slot:activator="{on}")
      v-btn(color="primary" small v-on="on" :disabled="disabled" outlined)  {{ btnText }}
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'

export default {
  mixins: [errorsMixin],

  props: {
    disabled: Boolean,
    activeItems: Array,
    btnText: {
      type: String,
      default: 'Assign labels'
    },
    mode: {
      type: String,
      default: 'assign'
    }
  },

  data: () => ({
    labels: []
  }),

  inject: ['svc'],

  methods: {
    submit(labels) {
      this.labels = labels
      if (this.mode === 'remove') {
        this.remove()
        return
      }
      this.send()
    },

    async remove() {
      try {
        let ids = this.activeItems.map(item => item.ID)
        await this.svc().unAssignLabels(ids, this.labels)
        this.$notify({ text: 'Labels removed', type: 'success' })
        this.reset()
      } catch (error) {
        this.processError(error, { redirectName: this.$ROUTER_NAMES.LOGIN_CRM })
      }
    },

    async send() {
      try {
        let ids = this.activeItems.map(item => item.ID)
        await this.svc().assignLabels(ids, this.labels)
        this.$notify({ text: 'Labels assigned', type: 'success' })
        this.reset()
      } catch (error) {
        this.processError(error, { redirectName: this.$ROUTER_NAMES.LOGIN_CRM })
      }
    },

    reset() {
      this.labels = []
    }
  },

  components: {
    labels: () => import('../CandidatesCustomLabels.vue')
  }
}
</script>